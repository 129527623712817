//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: "SizeTableStandart",
    mixins: [
        breakpointsListMixin,
    ],
    data: function () {
        return {
            isInch: false,
            tableData: {
                head: {
                    inch:       [this.$t('sizeTable.unitefitTops.collName'), "34 (3XS)", "38 (2XS)", "42 (XS)", "46 (S)", "50 (M)", "54 (L)", "58 (XL)", "62 (2XL)", "66 (3XL)", "70 (4XL)"],
                    centimeter: [this.$t('sizeTable.unitefitTops.collName'), "34 (3XS)", "38 (2XS)", "42 (XS)", "46 (S)", "50 (M)", "54 (L)", "58 (XL)", "62 (2XL)", "66 (3XL)", "70 (4XL)"],
                },
                body: {
                    inch: [
                        [this.$t('sizeTable.unitefitTops.rows.row1'), "28 3/4 - 30 3/4”", "30 3/4 - 32 1/4”", "32 3/4 - 34 3/4”", "35 - 37”", "37 1/2 - 39 1/4\"", "39 3/4 - 42 1/2\"", "43 - 46 1/2\"", "46 3/4 - 51 1/4\"", "51 1/2 - 56\"", "56 1/4 - 60 3/4\""],
                        [this.$t('sizeTable.unitefitTops.rows.row2'), "22 1/2 - 23 1/2\"", "24 - 26 3/4\"", "27 1/4 - 28 1/4\"", "28 3/4 - 30 3/4\"", "31 - 34 3/4\"", "35 - 37 3/4\"", "38 1/4 - 41 3/4\"", "42 1/4 - 46 3/4\"", "47 1/4 - 52\"", "52 1/4 - 57\""],
                        [this.$t('sizeTable.unitefitTops.rows.row3'), "32 - 33 1/2\"", "33 3/4 - 35 3/4\"", "36 1/4 - 38 1/4\"", "38 1/2 - 40 1/2\"", "41 - 43 1/4\"", "43 3/4 - 46\"", "46 1/2\" - 49 1/4\"", "49 1/2 - 51 1/4\"", "51 1/2 - 54\"", "54 1/4 - 57\""],
                    ],
                    centimeter: [
                        [this.$t('sizeTable.unitefitTops.rows.row1'), "73 - 78см", "78 - 82см", "83 - 88см", "89 - 94см", "95 - 100см", "101 - 108см", "109 - 118см", "131 - 137см", "131 - 142см", "143 - 154см"],
                        [this.$t('sizeTable.unitefitTops.rows.row2'), "57 - 60см", "61 - 68см", "69 - 72см", "73 - 78см", "79 - 88см", "89 - 96см", "97 - 106см", "107 - 119см", "120 - 132см", "133 - 145см"],
                        [this.$t('sizeTable.unitefitTops.rows.row3'), "81 - 85см", "86 - 91см", "92 - 97см", "98 - 103см", " ", "111 - 117см", "118 - 125см", "126 - 130см", "131 - 137см", "138 - 145см"],
                    ]
                }
            }
        }
    },
    methods: {},
    computed: {
        tableHeadData() {
            return this.isInch ? this.tableData.head.inch : this.tableData.head.centimeter
        },
        tableBodyData() {
            return this.isInch ? this.tableData.body.inch : this.tableData.body.centimeter
        },
        isMobile() {
            return this.mobileDown;
        },
        windowWidth() {
            return process.browser ? document.documentElement.clientWidth : 320;
        },
        isTable() {
            return this.tabletDown;
        },
        maxWidth() {
            return ((this.isMobile || this.isTable) ? (this.windowWidth - 40) : 680) + "px";
        }
    }

}
